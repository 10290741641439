import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Helper } from 'app/common/helper';
import { Constant } from 'app/config/constants';
import { DialogMessageComponent } from 'app/dialog/dialog-message/dialog-message.component';
import { NoticeText } from 'app/model/entity/schedule-operation-manager/notification-registration/NoticeText';
import { NotificationMedia } from 'app/model/entity/schedule-operation-manager/notification-registration/NotificationMedia';
import { NotificationMediaValidator } from 'app/model/entity/schedule-operation-manager/notification-registration/NotificationMediaValidator';
import { Folder } from 'app/model/entity/simple/folder';
import { Observable, Subject, from, throwError } from 'rxjs';
import { catchError, skip, take, toArray } from 'rxjs/operators';
import { DialogService } from './dialog.service';

@Injectable({
  providedIn: 'root'
})
export class ScheduleOperationManagerService {
  /**
   * Schedule operation information url
   */
  private scheduleOperationManagerUrl = Constant.BACKEND_URL + Constant.BID_SERVICE_URL + 'api/schedule-operation';

  private notificationRegistrationSequenceUrl = Constant.BACKEND_URL + Constant.BID_SERVICE_URL + 'api/notification-registration-sequence';

  tempoSuspension = '臨時運休';

  constructor(private http: HttpClient, private translateService: TranslateService, private dialogService: DialogService) {}

  /**
   * Save operation manager
   * @param payload
   * @returns
   */
  saveOperationManager(payload: any) {
    return this.http.post(`${this.scheduleOperationManagerUrl}/save-schedule-operation-info`, payload);
  }
  listScheduleOperationFromAPI: any;
  listScheduleOperation = new Subject<any>();
  headerScheduleOperations: any;

  getScheduleOperationFromAPI(index, size, isScrollDown = true): Observable<any> {
    // lazyloading data when scroll
    if (this.listScheduleOperationFromAPI && this.listScheduleOperationFromAPI.entry) {
      const schedulesDaily = this.listScheduleOperationFromAPI.entry;
      return from(schedulesDaily).pipe(
        skip(isScrollDown ? index : index - size * 4 > 0 ? index - size * 4 : 0),
        take(this.listScheduleOperationFromAPI.entry.length < index ? this.listScheduleOperationFromAPI.entry.length + size - index : size),
        toArray()
      );
    } else {
      return from([]);
    }
  }

  arrConvertData(index, size, isScrollDown = true, dataArr): any[] {
    //func to splice arr (lazyloading)
    let numDataNeedSplice = dataArr.length - size * 3;
    if (numDataNeedSplice < 0) {
      numDataNeedSplice = 0;
    }
    if (isScrollDown) {
      dataArr.splice(0, numDataNeedSplice);
    } else {
      dataArr.splice(size * 3, numDataNeedSplice);
    }
    return dataArr;
  }

  convertDataOperationApi() {
    this.listScheduleOperationFromAPI.entry.sort((a, b) =>
      parseInt(a.time.replace(/:?/g, '')) > parseInt(b.time.replace(/:?/g, '')) ? 1 : -1
    );

    this.listScheduleOperationFromAPI.entry.forEach((e, index) => {
      e.key = index + 1;
      if (!e.suspension) {
        e.suspension = false;
      }
      // if (e.item6 && (e.item6.toLowerCase() == 'temporary' || e.item6 == this.tempoSuspension)) {
      //   e.suspension = true;
      //   e.item6 = '';
      // }
    });
  }

  public getShowHiddenItem(areaName: string): Observable<any> {
    const params = new HttpParams().set('areaName', areaName);
    return this.http.get<any>(`${this.scheduleOperationManagerUrl}/get-all-items`, { params });
  }

  public saveShowHiddenItem(data: string, areaName): Observable<any> {
    const params = new HttpParams().set('areaName', areaName);
    return this.http.post<any>(`${this.scheduleOperationManagerUrl}/save-items`, data, { params });
  }

  public getAreaItemSetting(areaName: string): Observable<any> {
    const params = new HttpParams().set('areaName', areaName);
    return this.http.get<any>(`${this.scheduleOperationManagerUrl}/get-all-items-setting`, { params });
  }

  public saveAreaItemSetting(data: string, areaName): Observable<any> {
    const params = new HttpParams().set('areaName', areaName);
    return this.http.post<any>(`${this.scheduleOperationManagerUrl}/save-items-setting`, data, { params });
  }

  public getApiUpdate(): Observable<any> {
    return this.http.get(`${this.scheduleOperationManagerUrl}/get-api-update-data`, {
      responseType: 'text'
    });
  }

  /**
   * getAllNotificationSequence
   * @returns
   */
  public getAllNotificationSequence(): Observable<any> {
    return this.http.get<any>(`${this.notificationRegistrationSequenceUrl}/get-all-notification-sequence`).pipe(
      catchError(error => {
        const errorMessage =
          error.status == Constant.NETWORK_ERROR_CODE
            ? this.translateService.instant('dialog-error.error-network-api')
            : error.error
            ? error.error.message
            : this.translateService.instant('ticket-editor.common-error');
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('dialog-error.title'),
            text: errorMessage
          }
        });
        return throwError(error);
      })
    );
  }

  /**
   * getAllNotificationSequence
   * @returns
   */
  public getNotificationSequence(id: number): Observable<any> {
    if (Helper.isEmpty(id)) {
      return;
    }
    const params = new HttpParams().set('id', id.toString());
    return this.http
      .get<any>(`${this.notificationRegistrationSequenceUrl}/get-detail-notification-sequence`, { params })
      .pipe(
        catchError(error => {
          const errorMessage =
            error.status == Constant.NETWORK_ERROR_CODE
              ? this.translateService.instant('dialog-error.error-network-api')
              : error.error
              ? error.error.message
              : this.translateService.instant('ticket-editor.common-error');
          this.dialogService.showDialog(DialogMessageComponent, {
            data: {
              title: this.translateService.instant('dialog-error.title'),
              text: errorMessage
            }
          });
          return throwError(error);
        })
      );
  }

  /**
   * saveNotificationSequence
   * @param payload
   * @returns
   */
  public saveNotificationSequence(payload: any): Observable<any> {
    if (!payload) {
      return;
    }
    return this.http.post<any>(`${this.notificationRegistrationSequenceUrl}/save-notification-sequence`, payload).pipe(
      catchError(error => {
        const errorMessage =
          error.status == Constant.NETWORK_ERROR_CODE
            ? this.translateService.instant('dialog-error.error-network-api')
            : error.error
            ? error.error.message
            : this.translateService.instant('ticket-editor.common-error');
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('dialog-error.title'),
            text: errorMessage
          }
        });
        return throwError(error);
      })
    );
  }

  /**
   * duplicateNotificationSequence
   * @param payload
   * @returns
   */
  public duplicateNotificationSequence(payload: any): Observable<any> {
    if (!payload) {
      return;
    }
    return this.http.post<any>(`${this.notificationRegistrationSequenceUrl}/duplicate-notification-sequence`, payload).pipe(
      catchError(error => {
        const errorMessage =
          error.status == Constant.NETWORK_ERROR_CODE
            ? this.translateService.instant('dialog-error.error-network-api')
            : error.error
            ? error.error.message
            : this.translateService.instant('ticket-editor.common-error');
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('dialog-error.title'),
            text: errorMessage
          }
        });
        return throwError(error);
      })
    );
  }

  /**
   * deleteNotificationSequence
   * @param id
   * @returns
   */
  public deleteNotificationSequence(id: number): Observable<any> {
    if (Helper.isEmpty(id)) {
      return;
    }
    const params = new HttpParams().set('id', id.toString());
    return this.http
      .delete<any>(`${this.notificationRegistrationSequenceUrl}/delete-notification-sequence`, { params })
      .pipe(
        catchError(error => {
          const errorMessage =
            error.status == Constant.NETWORK_ERROR_CODE
              ? this.translateService.instant('dialog-error.error-network-api')
              : error.error
              ? error.error.message
              : this.translateService.instant('ticket-editor.common-error');
          this.dialogService.showDialog(DialogMessageComponent, {
            data: {
              title: this.translateService.instant('dialog-error.title'),
              text: errorMessage
            }
          });
          return throwError(error);
        })
      );
  }

  addNewNotificationMedia(file: File, folderId: Number): Observable<NotificationMedia> {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('folderId', `${folderId}`);
    formData.append('size', `${file.size}`);
    return this.http.post<NotificationMedia>(`${this.scheduleOperationManagerUrl}/add-new-notification-media`, formData);
  }

  /**
   * Be used by SCHEDULE_OPERATION_MANAGER
   * get all medias
   *
   * @returns
   */
  getAllMedia(): Observable<Array<NotificationMedia>> {
    return this.http.get<Array<NotificationMedia>>(`${this.scheduleOperationManagerUrl}/get-all-medias`);
  }

  /**
   * saveNoticeText
   */
  saveNoticeText(noticeTextDTO: any): Observable<NoticeText> {
    return this.http.post<NoticeText>(`${this.scheduleOperationManagerUrl}/save-notice-text`, noticeTextDTO);
  }

  /**
   * getAllNoticeTexts
   * @returns
   */
  getAllNoticeTexts(): Observable<Array<NoticeText>> {
    return this.http.get<Array<NoticeText>>(`${this.scheduleOperationManagerUrl}/get-all-notice-texts`);
  }

  /**
   * deleteNoticeTextById
   * @param noticeTextId
   * @returns
   */
  deleteNoticeTextById(noticeTextId: Number): Observable<string> {
    const params = new HttpParams().set('noticeTextId', noticeTextId + '');
    return this.http.delete(`${this.scheduleOperationManagerUrl}/delete-notice-text-by-id`, {
      params,
      responseType: 'text'
    });
  }

  /**
   * deleteMediaById
   * @param mediaId
   * @returns
   */
  deleteMediaById(mediaId: Number): Observable<string> {
    const params = new HttpParams().set('mediaId', mediaId + '');
    return this.http.delete(`${this.scheduleOperationManagerUrl}/delete-media-by-id`, {
      params,
      responseType: 'text'
    });
  }

  /**
   * getNotificationMediaValidator
   * @returns
   */
  public getNotificationMediaValidator(): Observable<NotificationMediaValidator> {
    return this.http.get<NotificationMediaValidator>(`${this.scheduleOperationManagerUrl}/get-notification-media-validator`);
  }

  /**
   * getListDetailNotificationSequence
   * @param listId
   * @returns
   */
  getListDetailNotificationSequence(listId: number[]): Observable<any[]> {
    return this.http.post<any[]>(`${this.notificationRegistrationSequenceUrl}/get-list-notification-sequence-by-ids`, listId);
  }

  /**
   * getAllFolderMedias
   * @returns
   */
  getAllFolderMedias(): Observable<Array<Folder>> {
    return this.http.get<Array<Folder>>(`${this.scheduleOperationManagerUrl}/get-all-folder-media`);
  }

  /**
   * saveFolder
   * @param scheduleOperationFolderMediaDTO
   * @returns
   */
  saveFolder(scheduleOperationFolderMediaDTO: Folder): Observable<Folder> {
    return this.http.post<Folder>(`${this.scheduleOperationManagerUrl}/save-folder-media`, scheduleOperationFolderMediaDTO);
  }

  /**
  deleteFolder(id: Number) {
   * 
   * @param id 
   * @returns 
   */
  deleteFolder(id: Number) {
    const params = new HttpParams().set('folderId', `${id}`);
    return this.http.delete(`${this.scheduleOperationManagerUrl}/delete-folder-media-by-id`, {
      params,
      responseType: 'text'
    });
  }

  /**
   * getMediasByPlaylistId
   * @param ids
   * @returns
   */
  getMediasByPlaylistId(ids: Number[]): Observable<Array<NotificationMedia>> {
    return this.http.post<any[]>(`${this.scheduleOperationManagerUrl}/get-medias-by-playlist-ids`, ids);
  }

  /**
   * getMediasByFolderId
   * @param folderId
   * @returns
   */
  getMediasByFolderId(folderId: Number): Observable<Array<NotificationMedia>> {
    const params = new HttpParams().set('folderId', `${folderId}`);
    return this.http.get<Array<NotificationMedia>>(`${this.scheduleOperationManagerUrl}/get-medias-by-folder-id`, {
      params
    });
  }

  /**
   * checkExistMediaUsed
   * @param idMedia
   * @returns
   */
  checkExistMediaUsed(idMedia: Number): Observable<boolean> {
    const params = new HttpParams().set('idMedia', `${idMedia}`);
    return this.http.get<boolean>(`${this.scheduleOperationManagerUrl}/check-exist-media-used`, {
      params
    });
  }

  /**
   * saveMediaDroppedInFolder
   * @param idMedia
   * @param folderId
   * @returns
   */
  saveMediaDroppedInFolder(idMedia: Number, folderId: Number): Observable<NotificationMedia> {
    const params = new HttpParams().set('folderId', folderId.toString()).set('idMedia', idMedia.toString());
    return this.http.post<NotificationMedia>(`${this.scheduleOperationManagerUrl}/save-media-dropped-in-folder`, null, { params });
  }
}
